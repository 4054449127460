<template>
  <div class="my_info_warp">
    <div class="wrap">
      <div class="head">
        <img src="@/static/myinfo/head.png"
             v-if="!form.avatar"
             @click="uploadImage"
             alt="">
        <img :src="form.avatar"
             v-else
             @click="uploadImage"
             alt="">
      </div>
      <a-form :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <!-- <a-row>
          <a-col :span="24">
            <a-form-item label="姓名">
              <a-input v-model:value="form.truename"
                       style="width:100%"
                       placeholder="请输入姓名"
                       disabled />
            </a-form-item>
          </a-col>
        </a-row> -->

        <a-row>
          <a-col :span="24">
            <a-form-item label="姓名">
              <a-input v-model:value="form.nickname"
                       style="width:100%"
                       placeholder="请输入姓名" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" >
            <a-form-item label="准考证号">
              <a-input v-model:value="form.admission_ticket"
                       style="width:100%"
                       disabled
                       placeholder="" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="性别">
              <a-select v-model:value="form.sex_id"
                        placeholder="请选择性别"
                        style="width:100%">
                <a-select-option v-for="item in sexList"
                                 :key="item.value"
                                 :value="item.value">
                  {{item.label}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
      <a-row>
        <a-button type="primary"
                  class="submit_btn"
                  @click="save">
          确 定
        </a-button>
      </a-row>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { getUserInfoApi, editUserInfo } from '@/api/user.js'
import { addHeader } from '@/init/index.js'
import { uploadImageAuto } from '@/api/upload.js'
import { message } from "ant-design-vue";

let form = reactive({
  avatar: ''
})
let sexList = [
  { label: '男', value: 1 },
  { label: '女', value: 2 },
  { label: '保密', value: 3 },
]

onMounted(() => {
  getInfo()
})

const getInfo = async () => {
  const { data } = await getUserInfoApi()
  if (data.avatar.indexOf('https') == -1 && data.avatar) {
    data.avatar = addHeader(data.avatar)
  }
  for (const key in data) {
    form[key] = data[key]
  }
}

const save = async () => {
  // let form = JSON.parse(JSON.stringify(form))
  form.nickname = form.nickname.trim()
  if (!form.nickname) {
    message.warning("请输入姓名")
    return
  }
  let pform = {}
  for (const key in form) {
    pform[key] = form[key]
  }
  delete pform.sex_name
  delete pform.grade_name
  console.log(pform)

  await editUserInfo(pform)
  message.success("修改成功")
  // // 修改成功后  获取当前用户的模式
  // const { data } = await getUserInfoApi()
  // let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
  // userInfo.mode = data.mode
  // window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
}

const uploadImage = async () => {
  const { data } = await uploadImageAuto(20, "image/*")
  form.avatar = addHeader(data.dir)
}



// export default {
//   data () {
//     return {
//       form: {},
//       gradeList: [],
//       modeList: [],
//       sexList: [
//         { label: '男', value: 1 },
//         { label: '女', value: 2 },
//         { label: '保密', value: 3 },
//       ],
//     }
//   },
//   watch: {
//     '$store.state.userInfo': {
//       handler () {
//         this.form.avatar = this.$addHeader(this.$store.state.userInfo.avatar)
//       },
//       deep: true
//     }
//   },
//   created () {
//     this.getGradeList()
//     this.getInfo()
//   },
//   methods: {
//     getGradeList () {
//       this.$http({
//         url: '/api/v1/public/grades',
//         method: 'get'
//       }).then(res => {
//         this.gradeList = []
//         res.data.list.forEach(item => {
//           item.grade_id = Number(item.grade_id)
//           this.gradeList.push(item)
//         })
//       })
//     },
//     getInfo () {
//       this.$http({
//         url: '/api/v1/user/get_user',
//         method: 'get'
//       }).then(res => {
//         if (res.data.avatar.indexOf('https') == -1 && res.data.avatar) {
//           res.data.avatar = this.$addHeader(res.data.avatar)
//         }
//         // res.data.edu_grade_id += ''
//         this.form = res.data
//         // this.getMode()
//       })
//     },
//     // getMode () {
//     //   this.$http({
//     //     url: '/api/v1/user/get_mode',
//     //     method: 'get',
//     //     params: {
//     //       grade_id: this.form.edu_grade_id
//     //     }
//     //   }).then(res => {
//     //     this.modeList = res.data.list
//     //   })
//     // },
//     save () {
//       let form = JSON.parse(JSON.stringify(this.form))
//       delete form.sex_name
//       delete form.grade_name
//       // delete form.edu_grade_id
//       // delete form.mode
//       this.$http({
//         url: '/api/v1/user/edit_user',
//         method: 'post',
//         data: form
//       }).then(res => {
//         let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
//         // userInfo.edu_grade_id = form.edu_grade_id
//         this.$message.success('修改成功')
//         this.$http({
//           url: '/api/v1/user/get_user',
//           method: 'get'
//         }).then(res => {
//           userInfo.mode = res.data.mode
//           window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
//         })
//       })
//     },
//     uploadImage () {
//       let userId = JSON.parse(window.sessionStorage.getItem('userInfo')).user_id
//       window.injectedObject.imageClick(userId, 1)
//     },
//     addImage (str) {
//       if (!str) {
//         this.$message.warning('拍照失败')
//         return
//       }
//       this.form.avatar = str
//       return str;
//     },
//   }
// }
</script>

<style lang="scss" scoped>
.my_info_warp {
  display: flex;
  .wrap {
    width: 596rem;
    // margin-left: 282rem;
    margin: 0 auto;
    .head {
      text-align: center;
      img {
        width: 150rem;
        height: 150rem;
        border-radius: 50%;
        margin: 60rem 0 40rem;
      }
    }
    .form_Warp {
      margin: 0 auto;
      margin-top: 22px;
    }
  }
}
.submit_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #2196f3;
  height: 60rem;
  width: 596rem;
  font-size: 24rem;
  background: #2196f3;
  border-radius: 10rem;
}
:deep .ant-form-item-label > label {
  font-size: 24rem;
  line-height: 60rem;
  height: 60rem;
}
:deep .ant-input {
  height: 60rem;
  font-size: 24rem;
}
:deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 60rem;
}
:deep .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 60rem;
  font-size: 24rem;
}
:deep .ant-form-item {
  margin-bottom: 24rem;
}
</style>